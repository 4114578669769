import Button from 'libs/button/Button'
import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetByRoleIdQuery } from '../roles-endpoints/roles.endpoint'
import { useUserInfoQuery } from '../../users/user-endpoints/user.endpoint'
import { useEffect, useState } from 'react'
import { getPages } from 'src/routes/paths'
import PaginatedTable from 'libs/table/PaginatedTable'
import GreenCheckbox from 'assets/icons/user-management/GreenCheckbox'
import Popup from 'libs/popup/Popup'
import DeleteRolesPopup from '../delete-roles/DeleteRolesPopup'
import FullPageLoader from 'libs/loader/FullPageLoader'
import useTabTitle from 'libs/hooks/useTabTitle'

const modules = [
  { name: 'Dashboard', id: 'das' },
  { name: 'Inventory', id: 'inv' },
  { name: 'Planning', id: 'pla' },
  { name: 'Production', id: 'pro' },
  { name: 'Review', id: 'rev' },
  { name: 'Order', id: 'ord' },
  { name: 'Employee', id: 'emp' },
  { name: 'Contacts', id: 'con' },
]

const ViewRolesDetail = () => {
  const paramId = useParams()
  useTabTitle('User-Roles')
  const [activeModuleId, setActiveModuleId] = useState<string | null>(null)
  const [fieldName, setFieldName] = useState('')
  const [data, setData] = useState<any>([])
  const [isPopupOpen, setIsPopupOpen] = useState({ isPopupOpen: false, roleData: null })
  const navigate = useNavigate()

  const dataRoute = getPages()
  // formatting the route in module with thier sub-modules
  const labelsAndIds = dataRoute?.routes
    ?.map((route: any) =>
      // Extract and flatten objects from `children` array
      route.children
        ?.map((child: any) => {
          // Extract objects from `child`'s `children` array
          const childItems = (child.children || [])
            .filter((subChild: any) => subChild?.label && subChild?.id && subChild?.key)
            .map((subChild: any) => ({ label: subChild.label, id: subChild.id, key: subChild.key }))

          // Add the direct `child`'s `label` and `id` if available
          if (child.label && child.id && child.key) {
            childItems.push({ label: child.label, id: child.id, key: child.key })
          }

          return childItems
        })
        // Flatten the results
        .flat(),
    )
    .flat()
    .filter((item: any) => item?.label && item.id && item.key) // Ensure no undefined items

  function groupByKey(data: any) {
    const keyMap = new Map()
    data.forEach((item: any) => {
      const { key } = item
      if (!keyMap.has(key)) {
        keyMap.set(key, [])
      }
      keyMap.get(key).push(item)
    })
    const groupedArray = Array.from(keyMap.values())
    return groupedArray
  }
  const accessRights = groupByKey(labelsAndIds)


  const handleModuleClick = (id: string) => {
    setActiveModuleId(id)
    const matchedAccessRights = accessRights?.find((group) => group.length > 0 && group[0].id === id)
    setFieldName(matchedAccessRights[0]?.label)

    if (matchedAccessRights) {
      const filteredData = matchedAccessRights.slice(1) // Remove the first element
      
      setData(filteredData) // Update the data state with the filtered data
    } else {
      setData([]) // If no match found, clear the data
    }
  }

  const { data: userInfo } = useUserInfoQuery({})
  const { data: roleData, isLoading: isRolesLoading } = useGetByRoleIdQuery(
    {
      roleId: paramId?.id,
      org_id: userInfo?.organization?._id,
    },
    {
      skip: !paramId?.id || !userInfo?.organization?._id,
    },
  )

  const convertToPermissionsArray = (data: any) => {
    if (!data) return [] // Return an empty array if data is undefined or null
    return Object?.keys(data)?.map((sectionKey) => {
      return Object?.keys(data[sectionKey])?.map((key) => {
        const id = key.replace(/_/g, ' ')
        const value = data[sectionKey][key]
        return {
          id: id,
          [value]: true,
        }
      })
    })
  }

  const result = convertToPermissionsArray(roleData?.modules) || [] // Ensure result is an array


  const accessData = data
    ?.map((primaryItem: any) => {
      for (const subArray of result) {
        const match = subArray?.find((secondaryItem) => secondaryItem?.id === primaryItem?.id)

        if (match) {
          return {
            label: primaryItem?.label,
            ...(('e' in match) && { e: match.e }),
            ...(('v' in match) && { v: match.v }),
          }
        }
      }
      return null // Or handle cases where no match is found
    })
    .filter((item: any) => item !== null)

    // if no accessData is available, use data as fallback
    const finalAccessData = accessData?.length > 0 ? accessData : data;
    


  useEffect(() => {
    // Trigger the handleModuleClick for the first module on mount
    if (modules.length > 0) {
      handleModuleClick(modules[0].id)
    }
  }, [])

  useEffect(() => {
    if (activeModuleId == null) {
      setActiveModuleId(modules[0]?.id)
    }
  }, [modules])

  const columns = [
    {
      Header: <div className="flex w-full items-center justify-end">User Roles</div>,
      accessor: 'label',
      width: 150,
      Cell: ({ row }: any) => {
        return <div>{row.original?.label}</div>
      },
    },
    {
      Header: 'View',
      accessor: 'v',
      width: 150,
      Cell: ({ value }: any) => {
        return (
          <div className="flex flex-wrap">
            <span className="text-[#434C52]">{value == true ? <GreenCheckbox /> : <h1>-</h1>}</span>
          </div>
        )
      },
    },
    {
      Header: 'Edit',
      accessor: 'e',
      Cell: ({ value }: any) => {
        return (
          <div className="flex w-full items-center justify-between ">
            {value == true ? <GreenCheckbox /> : <h1>-</h1>}
          </div>
        )
      },
    },
    {
      Header: 'None',
      accessor: 'n',
      width: 10,
      Cell: ({ value }: any) => {
        return (
          <div className="flex w-full items-center justify-between ">
            {value == true ? <GreenCheckbox /> : <h1>-</h1>}
          </div>
        )
      },
      disableSortBy: true,
    },
  ]

  let displayNames = roleData?.plants?.slice(0, 2).map((item: any) => item.name) // Display only the first two names
  const remainingCount = roleData?.length - displayNames?.length

  if (remainingCount > 0) {
    displayNames = [...displayNames, `${remainingCount}+`]
  }

  return (
    <MotionDiv>
      {isRolesLoading ? (
        <FullPageLoader />
      ) : (
        <>
          <WhiteCard>
            <div className="flex justify-between">
              <h5 className="h5">User Role Detail</h5>
              <div className="flex gap-2">
                <Button
                  color="error"
                  onClick={() => {
                    setIsPopupOpen({ isPopupOpen: true, roleData: roleData })
                  }}
                >
                  Delete User Role
                </Button>
                <Button color="primary" onClick={() => navigate(`/settings/user-management/roles/edit/${paramId?.id}`)}>
                  Update User Role
                </Button>
              </div>
            </div>
            <Popup
              isOpen={isPopupOpen.isPopupOpen}
              title="Delete User Role"
              onClose={() => {
                setIsPopupOpen({ isPopupOpen: false, roleData: null })
              }}
            >
              <DeleteRolesPopup
                closePopUp={() => setIsPopupOpen({ isPopupOpen: false, roleData: null })}
                data={isPopupOpen.roleData}
              />
            </Popup>
            <div className="my-2 grid grid-cols-[150px,1fr] gap-4 text-xs">
              <span className="text-gray-500">User Role</span>
              <span>{roleData?.name}</span>
              <span className="text-gray-500">Plants</span>
              {displayNames?.length > 0 ? (
                <div className="flex flex-wrap">
                  {Array.isArray(displayNames) ? (
                    displayNames?.map((name, index) => (
                      <span key={index} className={`mr-1 rounded-[28px] bg-[#E7F5FF] px-2 py-0.5 text-[#0B5ED7]`}>
                        {name}
                      </span>
                    ))
                  ) : (
                    <span className="text-[#434C52]">{displayNames}</span>
                  )}
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
            <div className="mb-6">
              <div className=" sm:w-full lg:w-10/12">
                <h5 className="h5">Access Rights</h5>
                <div className="mt-4 grid grid-cols-4">
                  <div className="col-span-1 border-r-2 border-border p-2">
                    {modules.map((module) => (
                      <div
                        key={module.id}
                        className={`cursor-pointer p-2 ${activeModuleId === module.id ? 'bg-[#E7F5FF]' : 'bg-white'} rounded-md`}
                        onClick={() => handleModuleClick(module.id)}
                      >
                        <h1 className="text-md">{module.name}</h1>
                      </div>
                    ))}
                  </div>
                  <div className="col-span-3 ml-6 text-lg">
                    <div className="flex items-center justify-between border-b-2 border-border p-2">
                      <div>
                        <h1 className="font-bold text-brand-primary-shade-3">{fieldName} Access</h1>
                        <h1 className="text-gray-800">({data?.length} Sub-Modules)</h1>
                      </div>
                      <div className="w-20"></div>
                    </div>
                    <div className="mt-6">
                      <PaginatedTable
                        columns={columns}
                        rows={finalAccessData}
                        hidePagination
                        emptyMessage="No access rights found for this  module."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </WhiteCard>
        </>
      )}
    </MotionDiv>
  )
}

export default ViewRolesDetail
