import { createBrowserRouter } from 'react-router-dom'
import { Suspense } from 'react'
import { Navigate } from 'react-router-dom'
import ProductionV2 from 'pages/productionV2/ProductionV2.jsx'
import OperationalProcesses from 'pages/operational-processes/OperationalProcesses.jsx'
import Department from 'pages/department/Department.jsx'
import Inventory from 'pages/inventory/index.tsx'
import AddEditInventory from 'pages/inventory/inventory-details/add-edit-inventory/index.tsx'
import Cookies from 'js-cookie'
import Products from 'pages/products/Products.jsx'
import NewProducts from 'pages/products/NewProducts.jsx'
import EditNewProducts from 'pages/products/EditNewProducts.tsx'
import AppSetting from 'pages/settings/AppSetting'
import Employee from 'pages/employee/Employee.tsx'
import CustomerManagement from 'pages/contacts/customer-management/CustomerManagement.tsx'
import AddEditCustomer from 'pages/contacts/customer-management/add-edit-customer/AddEditCustomer.tsx'
import EmployeeDepartmentPage from 'pages/employee/Employee-Department/EmployeeDepartmentPage.tsx'
import ViewDesignations from 'pages/employee/Employee-Department/view-department/ViewDepartment.tsx'
import ManufactureOrderDashboard from 'pages/dashboard/manufacture-order-dashboard/ManufactureOrderDashboard.tsx'
import TimeSheetPage from 'pages/employee/time-sheet/TimeSheetPage.tsx'
import AddEditRecord from 'pages/employee/time-sheet/add-edit-record/AddEditRecord.tsx'
import OperatorTimeSheetPage from 'pages/operator-timesheet/OperatorTimeSheetPage.tsx'

// Lazy loading components
const SuspenseFallback = (
  <div className="flex min-h-screen items-center justify-center">
    <FullPageLoader />
  </div>
)

import { Login, ForgotPasswordPage, VerificationCodePage, LoginPage } from 'libs/layouts/auth/login'
import PrivateRoutes from './PrivateRoutes.tsx'
import TempPassword from 'libs/layouts/auth/login/TempPassword.tsx'
import Review from 'pages/review/Review.jsx'
import Planning from 'pages/planning/index.jsx'
import Plans from 'pages/planning/production-plans/index.jsx'
import PlanningWizard from 'pages/planning/production-plans/planning-wizard.js'
import WareHouses from 'pages/warehouses/index.tsx'
import AddEditWarehouses from 'pages/warehouses/add-edit-warehouses/index.tsx'
import ViewDetails from 'pages/warehouses/view-warehouse-details/index.tsx'
import TransactionHistory from 'pages/inventory/inventory-details/transactionhistory/TransactionHistory.tsx'
import AddEditOperationalProcess from 'pages/operational-processes/add-edit-operational-process/AddEditOperationalProcess.tsx'
import EntriesDashboard from 'pages/productionV2/entries/EntriesDashboard.tsx'
import Dashboard from 'pages/dashboard/Dashboard.tsx'
import AddInputOutput from 'pages/productionV2/entries/AddInputOutput.tsx'
import Plants from 'pages/plants/Plants.tsx'
import ManufactureOrders from 'pages/manufacture-orders/ManufactureOrdersPage.tsx'
import ManufactureOrderWizard from 'pages/manufacture-orders/ManufactureOrderWizard.tsx'
import WorkOrders from 'pages/work-orders/WorkOrders.tsx'
import AddEditWorkOrder from 'pages/work-orders/add-edit-work-order/AddEditWorkOrder.tsx'
import Order from 'pages/order/Order.tsx'
import SalesOrder from 'pages/order/sales-order/SalesOrder.tsx'
import AddEditSalesOrder from 'pages/order/sales-order/add-sales-order/AddEditSalesOrder.tsx'
import FullPageLoader from 'libs/loader/FullPageLoader.tsx'
import ViewSalesOrder from 'pages/order/sales-order/view-sales-order/ViewSalesOrder.tsx'
import ViewManufactureOrder from 'pages/manufacture-orders/view-manufacture-order.tsx/ViewManufactureOrder.tsx'
import PurchaseOrder from 'pages/order/purchase-order/PurchaseOrder.tsx'
import ViewPurchaseOrder from 'pages/order/purchase-order/view-purchase-order/ViewPurchaseOrder.tsx'
import AddEditPurchaseOrder from 'pages/order/purchase-order/add-edit-purchase-order/AddEditPurchaseOrder.tsx'

import VendorManagement from 'pages/contacts/vendor-management/VendorManagement.tsx'
import AddEditVendor from 'pages/contacts/vendor-management/add-edit-vendor/AddEditVendor.tsx'
import ViewPlan from 'pages/planning/production-plans/details/ViewPlan.js'
import EmployeeManagementPage from 'pages/employee/Employee-Management/EmployeeManagementPage.tsx'
import EmployeeManagementWizard from 'pages/employee/Employee-Management/EmployeeManagementWizard.tsx'
import Contact from 'pages/contacts/Contact.tsx'
import AddEmployeeDepartment from 'pages/employee/Employee-Department/add-department/AddEditEmployeeDepartment.tsx'
import InventoryDashboard from 'pages/dashboard/inventory-dashboard/InventoryDashboard.tsx'
import WorkOrderDashboard from 'pages/dashboard/workorder-dashboard/WorkOrderDashboard.tsx'
import ViewWorkOrderDetails from 'pages/work-orders/view-work-order/ViewWorkOrderDetails.tsx'
import Users from 'pages/settings/user-management/users/Users.tsx'
import UserRoles from 'pages/settings/user-management/roles/UserRoles.tsx'
import AddEditRoles from 'pages/settings/user-management/roles/add-edit-roles/AddEditRoles.tsx'
import ViewRolesDetail from 'pages/settings/user-management/roles/view-roles-detail/ViewRolesDetail.tsx'
import { hasAccess } from 'utils/hasAccess.ts'
import AccessRoute from './AccessRoute.tsx'
import ShiftPolicyPage from 'pages/shift-management/ShiftPolicyPage.tsx'
import ViewShiftPolicy from 'pages/shift-management/view-shift-management/ViewShiftPolicy.tsx'
import Unauthorized_403 from './Unauthorized_403.tsx'
import AddEditTimesheet from 'pages/operator-timesheet/add-edit-timesheet/AddEditTimeSheet.tsx'

const role = Cookies.get('role')

const loginPage = [
  {
    path: 'auth',
    element: role ? <Navigate to="/login" replace /> : <Login />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPage />,
      },
      {
        path: 'verify-code',
        element: <VerificationCodePage />,
      },
      {
        path: 'temp-password',
        element: <TempPassword />,
      },
    ],
  },
]

const settings = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'settings/*',
        element: (
          <AccessRoute module="set" permission={{ set: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AppSetting />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'settings',
      },
    ].filter(Boolean),
  },
]
const productionV2 = [
  {
    path: '/',
    element: <PrivateRoutes />,
    handle: ['production', 'process', 'entries', 'add_entry', 'edit_entry'],
    children: [
      {
        path: 'productionV2',
        element: (
          <AccessRoute module="pro" permission={{ pro: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <ProductionV2 />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'production',
        label: 'Production',
        id: 'pro',
        key: 'pr',
      },
      {
        path: '/productionV2/:department/:process',
        element: (
          <AccessRoute module="pro" permission={{ pro: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <EntriesDashboard />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'entries',
        label: 'Production',
        id: 'prod',
        key: 'pr',
      },
      {
        path: '/productionV2/:department/:process/input',
        element: (
          <AccessRoute module="pro" permission={{ pro: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddInputOutput type="INPUT" />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'add/input',
        // label: 'Production',
        // id: 'pr',
        // key: 'pr',
      },
      {
        path: '/productionV2/:department/:process/input/:entryId',
        element: (
          <AccessRoute module="pro" permission={{ pro: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddInputOutput type="INPUT" />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'add/input',
      },
      {
        path: '/productionV2/:department/:process/output/:entryId',
        element: (
          <AccessRoute module="pro" permission={{ pro: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddInputOutput type="OUTPUT" />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'add/output',
      },
      {
        path: '/productionV2/:department/:process/output',
        element: (
          <AccessRoute module="pro" permission={{ pro: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddInputOutput type="OUTPUT" />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'add/output',
      },
    ].filter(Boolean),
  },
]

const shiftManagement = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'settings/shift-management/shift-policy',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ShiftPolicyPage />
          </Suspense>
        ),
        handle: 'add_shift_policy',
      },
      {
        path: 'settings/shift-management/view/:id',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ViewShiftPolicy />
          </Suspense>
        ),
        handle: 'view_shift_policy',
      },
      {
        path: 'settings/shift-management/edit/:id',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <ShiftPolicyPage />
          </Suspense>
        ),
        handle: 'edit_shift_policy',
      },
    ],
  },
]
const configure = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      // operational-processes - start
      {
        path: 'settings/configure/operationalprocesses',
        element: (
          <AccessRoute module="con" permission={{ ope: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <OperationalProcesses />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'configure_process',
      },
      {
        path: 'settings/configure/operationalprocesses/*',
        element: (
          <AccessRoute module="con" permission={{ ope: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditOperationalProcess />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'new_processes',
      },
      {
        path: 'settings/configure/preview/:process/input',
        element: role == 'admin' && (
          <AccessRoute module="con" permission={{ ope: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddInputOutput type="INPUT" />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'preview',
      },
      {
        path: 'settings/configure/preview/:process/output',
        element: (
          <AccessRoute module="con" permission={{ ope: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddInputOutput type="OUTPUT" />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'preview',
      },
      {
        path: 'settings/configure/department',
        element: (
          <AccessRoute module="con" permission={{ dep: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <Department />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'configure_department',
      },

      // operational-processes - products -start
      {
        path: 'settings/configure/products',
        element: (
          <AccessRoute module="con" permission={{ dep: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <Products />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'configure_products',
      },
      {
        path: 'settings/configure/products/newProducts',
        element: (
          <AccessRoute module="con" permission={{ pro: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <NewProducts />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'configure_products_new',
      },
      {
        path: 'settings/configure/products/editProducts',
        element: (
          <AccessRoute module="con" permission={{ pro: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <EditNewProducts />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'configure_products_edit',
      },
      // operational-processes - products -end

      // operational-processes - warehouse -start
      {
        path: 'settings/configure/warehouses',
        element: (
          <AccessRoute module="con" permission={{ war: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <WareHouses />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'configure_warehouses',
      },
      {
        path: 'settings/configure/warehouse/new',
        element: (
          <AccessRoute module="con" permission={{ war: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditWarehouses />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'new_warehouses',
      },
      {
        path: 'settings/configure/warehouses/:id',
        element: (
          <AccessRoute module="con" permission={{ war: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <ViewDetails />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'view_warehouses',
      },
      {
        path: 'settings/configure/warehouses/edit/:id',
        element: (
          <AccessRoute module="con" permission={{ war: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditWarehouses />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'edit_warehouses',
      },
      // Plants routes
      {
        path: 'settings/configure/plants',
        element: (
          <AccessRoute module="con" permission={{ pla: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <Plants />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'plants',
      },
    ],
  },
]

const userManagement = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'settings/user-management/users',
        element: (
          <AccessRoute module="con" permission={{ use: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <Users />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'users',
      },
      {
        path: 'settings/user-management/roles',
        element: (
          <AccessRoute module="con" permission={{ use: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <UserRoles />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'roles',
      },
      {
        path: 'settings/user-management/roles/add',
        element: (
          <AccessRoute module="con" permission={{ use: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditRoles />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'add_roles',
      },
      {
        path: 'settings/user-management/roles/edit/:id',
        element: (
          <AccessRoute module="con" permission={{ use: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditRoles />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'edit_roles',
      },
      {
        path: 'settings/user-management/roles/view/:id',
        element: (
          <AccessRoute module="con" permission={{ use: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <ViewRolesDetail />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'view_roles',
      },
    ],
  },
]
const review = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'review',
        element: (
          <AccessRoute module="rev" permission={{ rev: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <Review />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'review',
        label: 'Review',
        id: 'rev',
        key: 're',
      },
      {
        path: '/review/:department/:process',
        element: (
          <AccessRoute module="rev" permission={{ rev: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <EntriesDashboard />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'review_entries',
        label: 'Review',
        id: 're',
        key: 're',
      },
      {
        path: '/review/:department/:process/input',
        element: (
          <AccessRoute module="rev" permission={{ rev: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddInputOutput type="INPUT" />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'review_add_input',
      },
      {
        path: '/review/:department/:process/output',
        element: (
          <AccessRoute module="rev" permission={{ rev: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddInputOutput type="OUTPUT" />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'review_add_output',
      },
      {
        path: '/review/:department/:process/input/:entryId',
        element: (
          <AccessRoute module="rev" permission={{ rev: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddInputOutput type="INPUT" />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'review_add_input',
      },
      {
        path: '/review/:department/:process/output/:entryId',
        element: (
          <AccessRoute module="rev" permission={{ rev: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddInputOutput type="OUTPUT" />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'review_add_output',
      },
    ],
  },
]
const planning = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'planning',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Planning />
          </Suspense>
        ),
        handle: 'production_planning',
        label: 'Planning',
        id: 'pla',
        key: 'pl',
      },
      {
        path: 'planning/production-plans',
        element: (
          <AccessRoute module="pla" permission={{ pp: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <Plans />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'production_plans',
        label: 'Production plans',
        id: 'pp',
        key: 'pl',
      },
      {
        path: 'planning/production-plans/add/*',
        element: (
          <AccessRoute module="pla" permission={{ pp: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <PlanningWizard />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'new_plan',
      },
      {
        path: 'planning/production-plans/:id/add/*',
        element: hasAccess('pla', { pp: 'e' }) && (
          <AccessRoute module="pla" permission={{ pp: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <PlanningWizard />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'new_plan',
      },
      {
        path: 'planning/production-plans/:id/edit/*',
        element: (
          <AccessRoute module="pla" permission={{ pp: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <PlanningWizard />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'select_plan',
      },
      {
        path: 'planning/production-plans/view/:id',
        element: (
          <AccessRoute module="pla" permission={{ pp: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <ViewPlan />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'edit_plan',
      },

      //ManufactureOrders

      {
        path: 'planning/mo',
        element: (
          <AccessRoute module="pla" permission={{ mo: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <ManufactureOrders />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'manufacture_orders',
        label: 'Manufacture orders',
        id: 'mo',
        key: 'pl',
      },
      {
        path: 'planning/mo/add/*',
        element: (
          <AccessRoute module="pla" permission={{ mo: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <ManufactureOrderWizard />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'new_manufacture_order',
      },
      {
        path: 'planning/mo/:id/add/*',
        element: (
          <AccessRoute module="pla" permission={{ mo: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <ManufactureOrderWizard />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'new_manufacture_order',
      },
      {
        path: 'planning/mo/:id/edit/*',
        element: (
          <AccessRoute module="pla" permission={{ mo: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <ManufactureOrderWizard />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'edit_manufacture_order',
      },
      {
        path: 'planning/mo/view/:id',
        element: (
          <AccessRoute module="pla" permission={{ mo: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <ViewManufactureOrder />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'view_manufacture_order',
      },

      // work-orders
      {
        path: 'planning/work-orders',
        element: (
          <AccessRoute module="pla" permission={{ wo: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <WorkOrders />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'work_orders',
        label: 'Work Orders',
        id: 'wo',
        key: 'pl',
      },
      {
        path: 'planning/work-orders/add',
        element: (
          <AccessRoute module="pla" permission={{ wo: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditWorkOrder />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'add_work_order',
      },
      {
        path: 'planning/work-orders/edit/:id',
        element: (
          <AccessRoute module="pla" permission={{ wo: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditWorkOrder />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'edit_work_order',
      },
      {
        path: 'planning/work-orders/view/:id/*',
        element: (
          <AccessRoute module="pla" permission={{ wo: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <ViewWorkOrderDetails />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'view_work_order',
      },
    ],
  },
]
const inventory = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'inventory',
        element: (
          <AccessRoute module="inv" permission={{ inv: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <Inventory />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'inventory',
        label: 'Inventory',
        id: 'inv',
        key: 'in',
      },
      {
        path: 'inventory/new',
        element: (
          <AccessRoute module="inv" permission={{ inv: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditInventory />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'inventory_new',
        label: 'Inventory',
        id: 'in',
        key: 'in',
      },
      {
        path: 'inventory/transactionhistory/:id',
        element: (
          <AccessRoute module="inv" permission={{ inv: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <TransactionHistory />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'inventory_transaction_history',
      },
      {
        path: 'inventory/transactionhistory/edit/:id',
        element: (
          <AccessRoute module="inv" permission={{ inv: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditInventory />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'inventory_transaction_history_edit',
      },
    ],
  },
]

const order = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'orders',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Order />
          </Suspense>
        ),
        handle: 'order',
        label: 'Order',
        id: 'ord',
        key: 'or',
      },
      {
        path: 'orders/sales-order',
        element: (
          <AccessRoute module="ord" permission={{ so: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <SalesOrder />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'sales_order',
        label: 'Sales Order',
        id: 'so',
        key: 'or',
      },
      {
        path: 'orders/sales-order/newSalesOrder',
        element: (
          <AccessRoute module="ord" permission={{ so: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditSalesOrder />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'sales_order_new',
      },
      {
        path: 'orders/sales-order/edit/:id',
        element: (
          <AccessRoute module="ord" permission={{ so: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditSalesOrder />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'edit_sales_order',
      },
      {
        path: 'orders/sales-order/view/:id',
        element: (
          <AccessRoute module="ord" permission={{ so: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <ViewSalesOrder />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'view_sales_order',
      },
      {
        path: 'orders/purchase-order',
        element: (
          <AccessRoute module="ord" permission={{ po: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <PurchaseOrder />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'purchase_order',
        label: 'Purchase Order',
        id: 'po',
        key: 'or',
      },
      {
        path: 'orders/purchase-order/view/:id',
        element: (
          <AccessRoute module="ord" permission={{ po: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <ViewPurchaseOrder />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'view_purchase_order',
      },
      {
        path: 'orders/purchase-order/edit/:id',
        element: (
          <AccessRoute module="ord" permission={{ po: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditPurchaseOrder />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'edit_purchase_order',
      },
      {
        path: 'orders/purchase-order/add',
        element: (
          <AccessRoute module="ord" permission={{ po: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditPurchaseOrder />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'add_purchase_order',
      },
    ],
  },
]

//employee

const employee = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: 'employee',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Employee />
          </Suspense>
        ),
        handle: 'employee',
        label: 'Employee',
        id: 'emp',
        key: 'em',
      },
      {
        path: 'employee/employee-management',
        element: (
          <AccessRoute module="emp" permission={{ ed: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <EmployeeManagementPage />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'employee_management',
        label: 'Employee Department',
        id: 'ed',
        key: 'em',
      },
      {
        path: 'employee/employee-management/add/*',
        element: (
          <AccessRoute module="emp" permission={{ ed: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <EmployeeManagementWizard />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'add_employee',
      },
      {
        path: 'employee/employee-management/:id/edit/*',
        element: (
          <AccessRoute module="emp" permission={{ ed: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <EmployeeManagementWizard />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'edit_employee',
      },
      {
        path: 'employee/employee-management/:id/*',
        element: (
          <AccessRoute module="emp" permission={{ ed: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <EmployeeManagementWizard />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'view_employee',
      },

      {
        path: 'employee/employee-department',
        element: (
          <AccessRoute module="emp" permission={{ er: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <EmployeeDepartmentPage />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'employee_department',
        label: 'Employee Roles',
        id: 'er',
        key: 'em',
      },
      {
        path: 'employee/employee-department/add-employee-department',
        element: (
          <AccessRoute module="emp" permission={{ er: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEmployeeDepartment />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'add_employee_department',
      },
      {
        path: 'employee/employee-department/:id/edit-employee-department',
        element: (
          <AccessRoute module="emp" permission={{ er: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEmployeeDepartment />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'add_employee_designation',
      },
      {
        path: 'employee/employee-department/:id/view-employee-designation',
        element: (
          <AccessRoute module="emp" permission={{ er: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <ViewDesignations />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'view_employee_designation',
      },
      {
        path: 'employee/time-sheet',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <TimeSheetPage />
          </Suspense>
        ),
        handle: 'time_sheets',
      },
      {
        path: 'employee/time-sheet/add-record',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditRecord />
          </Suspense>
        ),
        handle: 'add_sheet',
      },
      {
        path: 'employee/time-sheet/:id/edit-record',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <AddEditRecord />
          </Suspense>
        ),
        handle: 'edit_sheet',
      },
    ],
  },
]

const unauthorized = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: '/unauthorized',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Unauthorized_403 />
          </Suspense>
        ),
      },
    ],
  },
]

const contact = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: '/contacts',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Contact />
          </Suspense>
        ),
        handle: 'contact',
        label: 'Contacts',
        id: 'con',
        key: 'co',
      },
      {
        path: '/contacts/customer-management',
        element: (
          <AccessRoute module="con" permission={{ cus: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <CustomerManagement />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'customer',
        label: 'Customer',
        id: 'cus',
        key: 'co',
      },
      {
        path: 'contacts/customer-management/add/*',
        element: (
          <AccessRoute module="con" permission={{ cus: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditCustomer />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'add_customer',
      },
      {
        path: 'contacts/customer-management/:id/edit/*',
        element: (
          <AccessRoute module="con" permission={{ cus: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditCustomer />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'edit_customer',
      },
      {
        path: 'contacts/customer-management/:id/*',
        element: (
          <AccessRoute module="con" permission={{ cus: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditCustomer />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'view_customer',
      },
      {
        path: 'contacts/vendor-management',
        element: (
          <AccessRoute module="con" permission={{ ven: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <VendorManagement />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'vendor_management',
        label: 'Vendors',
        id: 'ven',
        key: 'co',
      },
      {
        path: 'contacts/vendor-management/add/*',
        element: (
          <AccessRoute module="con" permission={{ ven: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditVendor />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'add_vendor',
      },
      {
        path: 'contacts/vendor-management/:id/edit/*',
        element: (
          <AccessRoute module="con" permission={{ ven: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditVendor />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'edit_vendor',
      },
      {
        path: 'contacts/vendor-management/:id/*',
        element: (
          <AccessRoute module="con" permission={{ ven: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditVendor />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'view_vendor',
      },
    ],
  },
]

//employee

const dashboard = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: '/dashboard',
        element: (
          <Suspense fallback={SuspenseFallback}>
            <Dashboard />
          </Suspense>
        ),
        handle: 'dashboard',
        label: 'Dashboard',
        id: 'das',
        key: 'da',
      },
      {
        path: '/dashboard/inventory',
        element: (
          <AccessRoute module="das" permission={{ id: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <InventoryDashboard />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'inventory_dashboard',
        label: 'Inventory Dashboard',
        id: 'id',
        key: 'da',
      },
      {
        path: '/dashboard/work-order',
        element: (
          <AccessRoute module="das" permission={{ wd: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <WorkOrderDashboard />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'workorder_dashboard',
        label: 'Workorder Dashboard',
        id: 'wd',
        key: 'da',
      },
      {
        path: '/dashboard/manufacture-order',
        element: (
          <AccessRoute module="das" permission={{ md: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <ManufactureOrderDashboard />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'manufacture_dashboard',
        label: 'Manufacture Dashboard',
        id: 'md',
        key: 'da',
      },
    ],
  },
]

const Timesheets = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: '/timesheet',
        element: (
          <AccessRoute module="tim" permission={{ ts: 'v' }}>
            <Suspense fallback={SuspenseFallback}>
              <OperatorTimeSheetPage />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'operator_timesheet',
        label: 'Time Sheet',
        id: 'tim',
        key: 'ts',
      },
      {
        path: '/timesheet/add-record',
        element: (
          <AccessRoute module="tim" permission={{ ts: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditTimesheet />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'add_operator_timesheet',
        label: 'Time Sheet',
        id: 'ti',
        key: 'ts',
      },
      {
        path: '/timesheet/:id/edit-record',
        element: (
          <AccessRoute module="tim" permission={{ ts: 'e' }}>
            <Suspense fallback={SuspenseFallback}>
              <AddEditTimesheet />
            </Suspense>
          </AccessRoute>
        ),
        handle: 'edit_operator_timesheet',
      },
    ],
  },
]

export const getPages = () => {
  const routes: any = [
    ...loginPage,
    ...settings,
    ...productionV2,
    ...configure,
    ...shiftManagement,
    ...userManagement,
    ...review,
    ...planning,
    ...inventory,
    ...dashboard,
    ...unauthorized,
    ...order,
    ...employee,
    ...contact,
    ...Timesheets,
    ...unauthorized,
    { path: '*', element: <Navigate to="/dashboard" replace /> },
  ]

  return createBrowserRouter(routes)
}

export const router = getPages()
